import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from 'chart.js';

// Register the components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const AnalyticsLinearChart: React.FC = () => {
  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Replies',
        data: [420, 400, 200, 420, 530, 450, 560, 420, 500, 480, 400, 410],
        borderColor: '#34c759',
        backgroundColor: '#34c759',
        tension: 0.4, // Smooth the line
        fill: false,
      },
      {
        label: 'Conversations',
        data: [380, 390, 460, 510, 450, 570, 560, 380, 430, 450, 380, 420],
        borderColor: '#0062ff',
        backgroundColor: '#0062ff',
        tension: 0.4, // Smooth the line
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows height customization
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const chartContainerStyle = {
    height: '500px',
    width: '100%',
  };

  return (
    <div className="d-flex-start-start w-100 bg-white p-1_125rem-1_625rem mt-1_75rem b-radius-1_25rem">
      <div className="d-flex-row w-100 pl-1rem">
        <div className="d-flex-row mr-3rem">
          <div className="d-flex-center w-0_75rem h-0_75rem bg-ff0044 b-radius-0_375rem mr-1rem"></div>
          <span className="fs-0_875rem">Leads</span>
        </div>
        <div className="d-flex-row mr-3rem">
          <div className="d-flex-center w-0_75rem h-0_75rem bg-0062ff b-radius-0_375rem mr-1rem"></div>
          <span className="fs-0_875rem">Messages Sent</span>
        </div>
        <div className="d-flex-row mr-3rem">
          <div className="d-flex-center w-0_75rem h-0_75rem bg-34c759 b-radius-0_375rem mr-1rem"></div>
          <span className="fs-0_875rem">Replies</span>
        </div>
        <div className="d-flex-row mr-3rem">
          <div className="d-flex-center w-0_75rem h-0_75rem bg-8000ffc4 b-radius-0_375rem mr-1rem"></div>
          <span className="fs-0_875rem">Meetings Booked</span>
        </div>
      </div>
      <div style={chartContainerStyle}>
        <Line data={data} options={options} className="p-1rem" />
      </div>
    </div>
  );
};

export default AnalyticsLinearChart;
