import React from 'react';

const EngagementSummary: React.FC = () => {
  return (
    <div className="d-flex-row-space-between w-100 mt-1_75rem">
      <div className="d-flex-start-start w-calc-25_minus_0_844rem bg-white b-radius-1_25rem p-1_25rem-1_75rem ">
        <span className="ff-poppins fw-500 lh-1_5rem c-regular pb-0_625rem">
          Leads
        </span>
        <span className="ff-poppins fw-600 fs-1_75rem lh-2_625rem c-regular">
          1522
        </span>
      </div>
      <div className="d-flex-start-start w-calc-25_minus_0_844rem bg-white b-radius-1_25rem p-1_25rem-1_75rem ">
        <span className="ff-poppins fw-500 lh-1_5rem c-regular pb-0_625rem">
          Conversation Started
        </span>
        <span className="ff-poppins fw-600 fs-1_75rem lh-2_625rem c-regular">
          981
        </span>
      </div>
      <div className="d-flex-start-start w-calc-25_minus_0_844rem bg-white b-radius-1_25rem p-1_25rem-1_75rem ">
        <span className="ff-poppins fw-500 lh-1_5rem c-regular pb-0_625rem">
          Replies
        </span>
        <span className="ff-poppins fw-600 fs-1_75rem lh-2_625rem c-regular">
          981
        </span>
      </div>
      <div className="d-flex-start-start w-calc-25_minus_0_844rem bg-white b-radius-1_25rem p-1_25rem-1_75rem ">
        <span className="ff-poppins fw-500 lh-1_5rem c-regular pb-0_625rem">
          Meetings Booked
        </span>
        <span className="ff-poppins fw-600 fs-1_75rem lh-2_625rem c-regular">
          309
        </span>
      </div>
    </div>
  );
};

export default EngagementSummary;
