import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

import { IncreaseArrowIcon } from '../../../../assets';

// Register the necessary components
Chart.register(ArcElement, Tooltip, Legend);

const SMSActivityStats: React.FC = () => {
  const data = {
    labels: ['Credits Used', 'Credits Remaining'],
    datasets: [
      {
        data: [20, 80], // Values for credits used and remaining
        backgroundColor: ['#FF0000', '#34C759'], // Colors for the sections
        borderWidth: 0, // No border
        hoverOffset: -8,
        spacing: 2,
        borderRadius: 50, // This will round the corners
        cutout: '70%', // Controls the inner radius (for the donut effect)
      },
    ],
  };

  // Chart options
  const options = {
    rotation: 210, // Starts the chart from the bottom (in degrees)
    circumference: 360, // Complete circle (default)
    cutoutPercentage: 70,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend with color blocks
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  return (
    <div className="d-flex-start-start bg-white w-calc-50_minus_0_625rem b-radius-1_25rem pt-1rem pl-2_625rem pr-2rem pb-4rem">
      <div className="d-flex-center w-100">
        <span className="ff-poppins c-regular lh-1_5rem fw-600 ls-0_00625rem">
          SMS
        </span>
      </div>
      <div className="d-flex-start-start w-100">
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ffcc00 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Messages Sent <span className="fw-700">5.1k</span>
            </span>
          </div>
          <div className="d-flex-row">
            <div className="d-flex-center mr-0_375rem">
              <IncreaseArrowIcon />
            </div>
            <span className="fs-0_875rem">500%</span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '50%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-075b59 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Messages Opened <span className="fw-700">2.1k</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-1e92f4 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Message Replies <span className="fw-700">1.1k</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
      </div>
      <div className="d-flex-row-space-between w-100 mt-2_75rem">
        <div className="d-flex-start-start">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ff0000 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_75rem ls-0_05rem c-8c8c8c  ff-archivo">
              Credits Used
            </span>
          </div>
          <span className="ff-archivo fw-600 pt-1_5rem pl-1_5rem fs-1_25rem c-black ls-0_05rem">
            634
          </span>
        </div>
        <div className="d-flex-center w-11rem h-11rem p-relative z1 ">
          <Doughnut data={data} options={options} />
          <div className="d-flex-center w-100 h-100 p-absolute left-0 top-0 z-1">
            <span className="fs-1_5rem ff-archivo c-black fw-500 ls-0_05rem">
              Credits
            </span>
          </div>
        </div>
        <div className="d-flex-start-start">
          <div className="d-flex-row">
            <div className="d-flex-center bg-34c759 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_75rem ls-0_05rem c-8c8c8c  ff-archivo">
              Credits Remaining
            </span>
          </div>
          <span className="ff-archivo fw-600 pt-1_5rem pl-1_5rem fs-1_25rem c-black ls-0_05rem">
            1,222,812
          </span>
        </div>
      </div>
    </div>
  );
};

export default SMSActivityStats;
