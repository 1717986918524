import React from 'react';
import { AstreauxActivityStats, SMSActivityStats } from './ActivityStats';

const LeadActivityCharts: React.FC = () => {
  return (
    <div className="d-flex-row-space-between w-100 mt-1_75rem">
      <SMSActivityStats />
      <AstreauxActivityStats />
    </div>
  );
};

export default LeadActivityCharts;
