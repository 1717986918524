import React from 'react';
import { IncreaseArrowIcon } from '../../../../assets';

const InstagramActivityStats: React.FC = () => {
  return (
    <div className="d-flex-start-start bg-white w-calc-33_3_minus_1_3rem b-radius-1_25rem pt-1rem pl-2rem pr-1rem pb-8_5rem">
      <span className="ff-poppins c-regular lh-1_5rem fw-600 pl-4_5rem ls-0_00625rem">
        Instagram
      </span>
      <div className="d-flex-start-start w-100">
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ffcc00 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Total Leads <span className="fw-700">5.1k</span>
            </span>
          </div>
          <div className="d-flex-row">
            <div className="d-flex-center mr-0_375rem">
              <IncreaseArrowIcon />
            </div>
            <span className="fs-0_875rem">500%</span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '85%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-075b59 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Messages Started <span className="fw-700">2.1k</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-1e92f4 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              New Leads <span className="fw-700">1.1k</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ff3b30 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Returning Leads <span className="fw-700">653</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-34c759 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Ad Impressions <span className="fw-700">12.8k</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '75%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ff974a w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Clicks to Ads <span className="fw-700">12.8k</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-1067db w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Conversation Rate <span className="fw-700">45%</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '45%' }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default InstagramActivityStats;
