import React from 'react';
import {
  FacebookActivityStats,
  InstagramActivityStats,
  WhatsAppActivityStats,
} from './ActivityStats';

const LeadActivityStats: React.FC = () => {
  return (
    <div className="d-flex-row-space-between w-100 mt-1_75rem">
      <FacebookActivityStats />
      <InstagramActivityStats />
      <WhatsAppActivityStats />
    </div>
  );
};

export default LeadActivityStats;
